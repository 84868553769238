import router from './index'
import store from '@/store'

const whiteList = [
  '/login',
  '/home',
  '/home',
  '/productOne',
  '/productTwo',
  '/applicationPhone',
  '/applicationMini',
  '/companyNews',
  '/contactUs',
  '/privacyPolicy',
  '/license'
]

router.beforeEach((to, from, next) => {
  if (store.getters.token && to.path !== '/home') {
    if (to.path === '/login') {
      next('/')
    } else {
      next()
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next('/home')
    }
  }
})
