import axios from 'axios'
import { ElMessage } from 'element-plus'
// import { diffTokenTime } from '@/utils/auth'
// diffTokenTime
import store from '@/store'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 180000,
  headers: { 'Content-Type': 'application/json;charset=utf-8' }
})

service.interceptors.request.use(
  (config) => {
    // 固定时间的跳转登录
    // if (localStorage.getItem('token')) {
    //   if (diffTokenTime()) {
    //     store.dispatch('app/logout')
    //     return Promise.reject(new Error('token 失效了'))
    //   }
    // }
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config
  },
  (error) => {
    return Promise.reject(new Error(error))
  }
)

service.interceptors.response.use(
  (response) => {
    const resData = response.data
    if (
      response.status === 200 ||
      response.status === 201 ||
      resData.status === 'success'
    ) {
      if (resData.error_code === 101006) {
        // console.log('token过期')
        store.dispatch('app/logout')
      }
      if (response.headers['content-disposition'] && response.headers['content-disposition'].includes('xls')) {
        // 文件下载
        return response
      }
      return resData
    } else {
      ElMessage.error(resData.status || '响应异常')
      return Promise.reject(new Error(resData.status))
    }
  },
  (error) => {
    console.log(error.response)
    error.response && ElMessage.error(error.response.data)
    return Promise.reject(new Error(error.response.data))
  }
)
export default service
