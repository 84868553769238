import request from './request'

export const login = (data) => {
  return request({
    url: '/jf-tech/v1/login',
    method: 'POST',
    data
  })
}

// 管理员登录
export const loginGly = (data) => {
  return request({
    url: '/rame/gsfms/gsf_login',
    method: 'POST',
    data
  })
}
// 服务站登录
export const loginFwz = (data) => {
  return request({
    url: '/rame/gsfms/fwz_login',
    method: 'POST',
    data
  })
}

// 输入验证码
export const loginFwzCode = (data) => {
  return request({
    url: '/rame/gsfms/fwz_login/code',
    method: 'POST',
    data
  })
}
